import { HelmetProvider } from 'react-helmet-async'
import GuildHeader from './Components/GuildHeader'
import VipProgress from './Components/VipProgress'
import Meta from '../../components/Meta'
import ImageClose from './images/close.png'

import ImageGuildId from '../../images/guild-id.png'
// import ImageGuildFlag from '../../images/guild-flag.png'
import ImageGuildMembers from '@/images/guild-members.png'
import ImageGroup from '../../images/guild-group.png'
import ImageMessage from '../../images/guild-message.png'
// import ImageExchangeIcon from '../../images/guild-n-exchange-icon.png'
import ImageInformation from './images/icon-info.png'
import ImageArrow from '../../images/arrow.png'
import ImageArrowDown from '../../images/arrowdown.png'
import ImageGem from '../../images/gem.png'

import GuildPane from '../../components/Pane'
import HistoricalCard from './Components/NumberCard'
import MemberCard from './Components/MemberCard'
// import Func from './Components/Function'
import { Popup, ProgressBar, Mask } from 'antd-mobile'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import MessageCard from './Components/MessageCard'
import {
  aduitGuildMemberApply,
  dissolveGuild,
  exitGuild,
  getGuildApplyMessageList,
  getGuildFansList,
  getGuildMemberList,
  getQuitCost,
  inviteMember,
  getMissionUserHome,
  getGuildHomeDetail,
  getGuildHomeMyselfData,
  getGuildHomeGuildData,
  getUnclaimedRewards,
  openUnclaimedReward,
  guildManagerAdd,
  guildManagerDel
} from '@/utils/request'
import GuildLoading from '../../components/Loading'
import { useNavigate } from 'react-router'
import ScrollList from '../../components/ScrollList'
import {
  alert,
  getUserInfo,
  readedMessage,
  showAlertWithWarn,
  toast
} from '../../utils/bridge'
import { GuildContext } from '../../store/guild'
import SafeArea from '../../components/SafeArea'
import { formatGuildTime } from '../../utils/format'
// import PayInfo from './Components/PayInfo'
import Withdraw from './Components/Withdraw'
// import Exchange from './Exchange'
import TaskProgress from './Components/TaskProgress';
import ScrollTabs from '@/components/ScrollTabs';
import clsx from 'clsx'
import { useTrans } from '@/hooks/useTranslation'
import './Mine.scss'
import { useRefState } from '@/hooks'
import moment from 'moment'

//用户身份：1公会长,2管理员,3普通成员
const OwnerIdentity = [1]
const ManagerIdentity = [1, 2]

export default function GuildMine() {
  const { t } = useTrans()
  const navigate = useNavigate()
  const { dispatch } = useContext(GuildContext)

  const [loading, setLoading] = useState(true)
  const refLoading = useRef(loading)
  refLoading.current = loading

  const [user, setUser] = useState<UserBasicInfo>()
  const refUser = useRef(user)
  refUser.current = user

  const [guild, setGuild] = useState<Guild.GuildHomeDetail>()
  const refGuild = useRef(guild)
  refGuild.current = guild

  const [guildMyself, setGuildMyself] = useState<Guild.GuildHomeMyselfData>()
  const [guildTotal, setGuildTotal] = useState<Guild.GuildHomeGuildData>()

  // const [viewPayInfo, setViewPayInfo] = useState<boolean>(false)

  const [viewWithdraw, setViewWithdraw] = useState<boolean>(false)

  const [viewRewardsDialog, setRewardsViewDialog] = useState(false)

  const [selectedTab, setSelectedTab] = useState<number>(0)
  // const [exchangeDraw, setExchangeDraw] = useState<boolean>(false)

  const [role, setRole, refRole] = useRefState<number>(0)

  const [memberList, setMemberList] = useState<{
    page: number
    hasMore: boolean
    visible: boolean
    list: Guild.MemberItem[]
  }>({ page: 1, hasMore: true, visible: false, list: [] })
  const refMemberList = useRef(memberList)
  refMemberList.current = memberList

  const [applyMessageList, setApplyMessageList] = useState<{
    page: number
    hasMore: boolean
    visible: boolean
    list: Guild.ApplyMessageItem[]
  }>({ page: 1, hasMore: true, visible: false, list: [] })
  const refApplyMessageList = useRef(applyMessageList)
  refApplyMessageList.current = applyMessageList

  const [fansList, setFansList] = useState<{
    pageNum: number
    hasMore: boolean
    visible: boolean
    list: Guild.Fans[]
  }>({ pageNum: 1, hasMore: true, visible: false, list: [] })
  const refFansList = useRef(fansList)
  refFansList.current = fansList

  const [functions, setFunctions] = useState<Guild.FunctionItem[]>([])
  const refFunctions = useRef(functions)
  refFunctions.current = functions

  /* task center数据 */
  const [userMissionData, setUserMissionData] = useState<Guild.GuildMissionUserHome>({
    taskReward: 0,
    taskCompleted: 0,
    missionList: [{
      missionTypeName: '',
      userMissionList: [{
        missionName: '',
        missionDescription: '',
        conditionNum: '0',
        userCompleteNum: 0,
        goodsType: 0,
        number: '0',
        missionStatus: 1
      }]
    }]
  })

  /* 获取未领取的宝箱奖励 */
  const [unclaimedRewards, setUnclaimedRewards] = useState<Guild.UnclaimedRewards & {
    isOpen: boolean
  }>({
    rewardList: [],
    isBDUser: false,
    isOpen: false,
    type: 1
  })

  /* agency Function 图标根据权限展示 */
  useEffect(() => {
    setFunctions([
      { key: 'member', icon: ImageGroup, name: 'Common.Member' },
      {
        key: 'information',
        icon: ImageInformation,
        name: 'Common.Information'
      }
    ])
  }, [])

  /* GuildHeader头部项图标展示 */
  const infoCardItems = useMemo(() => {
    const items = [
      { image: ImageGuildId, text: guild?.guildNo.toString() || '' },
      {
        image: ImageGuildMembers,
        text: guild?.memberCount.toString() || ''
      }]
    /* 公会长 */
    // if ([1].includes(guild?.memberRole || 0)) {
    //   items.push({
    //     image: ImageGuildFlag,
    //     text: guild?.subGuildNum?.toString() || ''
    //   })
    // }
    return items
  }, [guild])

  /* 请求 */
  const fetchGuildHomeDetail = useCallback(async () => {
    const { code, message, data } = await getGuildHomeDetail()
    setLoading(false)
    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) {
      toast(t('Guild.Tips.YouHasNoGuild').toString())
      return
    }
    setGuild(data)

    dispatch &&
      dispatch({
        type: 'update',
        data: {
          guildId: data.guildId,
          guildName: data.guildName,
          guildImage: data.coverPicUrl,
          guildSlogan: data.guildSlogan,
          guildNo: data.guildNo
        }
      })

    setRole(data.memberRole)
  }, [t, dispatch])

  const fetchGuildHomeMyselfData = useCallback(async () => {
    const { code, message, data } = await getGuildHomeMyselfData()
    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) return

    setGuildMyself(data)
  }, [])

  const fetchGuildHomeGuildData = useCallback(async () => {
    const { code, message, data } = await getGuildHomeGuildData()
    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) return

    setGuildTotal(data)
  }, [])

  const fetchGuildMemberList = useCallback(async () => {
    if (!refMemberList.current.hasMore) return

    const res = await getGuildMemberList(refMemberList.current.page)
    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<PageList<Guild.MemberItem>>
    if (code !== 200) {
      toast(message)
      return
    }
    setMemberList({
      ...refMemberList.current,
      list: [
        ...(refMemberList.current.page === 1 ? [] : refMemberList.current.list),
        ...(data.list || [])
      ],
      hasMore: !data.whetherLastPage,
      page: data.whetherLastPage
        ? refMemberList.current.page
        : refMemberList.current.page + 1
    })
  }, [])

  const fetchGuildApplyMessageList = useCallback(async () => {
    if (
      !refGuild.current ||
      !ManagerIdentity.includes(refGuild.current.memberRole) ||
      !refApplyMessageList.current.hasMore
    )
      return

    const res = await getGuildApplyMessageList(
      refApplyMessageList.current.page,
      3
    )
    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<
      PageList<Guild.ApplyMessageItem>
    >
    if (code !== 200) {
      toast(message)
      return
    }
    setApplyMessageList({
      ...refApplyMessageList.current,
      list: [
        ...(refApplyMessageList.current.page === 1
          ? []
          : refApplyMessageList.current.list),
        ...(data.list || [])
      ],
      hasMore: !data.whetherLastPage,
      page: data.whetherLastPage
        ? refApplyMessageList.current.page
        : refApplyMessageList.current.page + 1
    })
  }, [])

  const fetchGuildFansList = useCallback(async (scroll?: boolean) => {
    if (
      !refGuild.current ||
      !ManagerIdentity.includes(refGuild.current.memberRole) ||
      !refFansList.current.hasMore
    )
      return

    const res = await getGuildFansList({
      pageNum: refFansList.current.pageNum,
      pageSize: 20
    })
    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<PageList<Guild.Fans>>
    if (code !== 200) {
      toast(message)
      return
    }

    setFansList({
      ...refFansList.current,
      list: !scroll
        ? [...data.list]
        : [...refFansList.current.list, ...data.list],
      hasMore: !data.whetherLastPage,
      pageNum: !data.whetherLastPage
        ? refFansList.current.pageNum + 1
        : refFansList.current.pageNum
    })
  }, [])

  const fetchMissionUserHome = useCallback(async () => {
    const { code, data, message } = await getMissionUserHome() as Response<Guild.GuildMissionUserHome>
    if (code !== 200) {
      toast(message)
      return
    }
    if (!data) return
    setUserMissionData(data)
  }, [])

  const fetchUnclaimedRewards = useCallback(async () => {
    const { code, data, message } = await getUnclaimedRewards()
    if (code !== 200) {
      toast(message)
      return
    }
    if (!data) return
    setUnclaimedRewards(prev => ({
      ...prev,
      ...data
    }))
    if (data.rewardList) {
      setRewardsViewDialog(true)
    }
  }, [])

  const fetchOpenUnclaimedReward = useCallback(async () => {
    if (refLoading.current) return
    setLoading(true)
    const { code, message } = await openUnclaimedReward()
    setLoading(false)
    if (code !== 200) {
      toast(message)
      return
    }
    setUnclaimedRewards(prev => ({
      ...prev,
      isOpen: true
    }))
  }, [])

  const fetchGuildManagerAdd = useCallback(async (managerUid: number) => {
    if (refLoading.current) return
    setLoading(true)
    const { code, message } = await guildManagerAdd(managerUid)
    setLoading(false)
    if (code !== 200) {
      toast(message)
      return
    }
  }, [])

  const fetchGuildManagerDel = useCallback(async (managerUid: number) => {
    if (refLoading.current) return
    setLoading(true)
    const { code, message } = await guildManagerDel(managerUid)
    setLoading(false)
    if (code !== 200) {
      toast(message)
      return
    }
  }, [])

  /* 初始化 */
  useEffect(() => {
    if (!guild) {
      fetchGuildHomeDetail()
      fetchGuildHomeMyselfData()
      fetchGuildMemberList()
      fetchMissionUserHome()
      fetchUnclaimedRewards()
      getUserInfo().then((auth) => {
        if (auth) {
          setUser(auth)
        }
      })
    } else if (ManagerIdentity.includes(guild.memberRole)) {
      fetchGuildHomeGuildData()
      fetchGuildFansList()
      fetchGuildApplyMessageList()
    }
  }, [guild])

  /* 公会头像下面三个图标点击 */
  const handleCardClick = useCallback((index: number) => {
    switch (index) {
      case 1:
        setMemberList({
          ...refMemberList.current,
          visible: true
        })
        break
      // case 2:
      //   navigate('/guild/agency-reward')
      //   break
      default:
    }
  }, [])

  /* agency function => Member：成员点击跳转 */
  const handleMemberCardClick = useCallback(
    (memberId: number) => {
      if (ManagerIdentity.includes(refGuild.current?.memberRole || 0)) {
        navigate('/guild/member/' + memberId)
      }
    },
    [navigate]
  )
  /* 管理员设置 */
  const handleManagerClick = useCallback(
    async (memberId: number, role: number) => {
      if (!role || !memberId) return
      const ret = await alert(t(`Guild.${role === 2 ? 'WhetherToCancelTheManager' : 'WhetherToSetHimAsManager'}`))
      if (ret) {
        role === 2 ? fetchGuildManagerDel(memberId) : fetchGuildManagerAdd(memberId)
        setMemberList({
          ...refMemberList.current,
          hasMore: true,
          page: 1
        })
        fetchGuildMemberList()
      }
    },
    []
  )

  /* pane各标题卡跳转 */
  const handleHistoricalCardClick = useCallback(async (index: number, tab: number) => {
    if (refGuild.current && ManagerIdentity.includes(refGuild.current.memberRole) && tab === 0) {
      switch (index) {
        case 1:
          navigate('/guild/task-data/member-list')
          break
        case 2:
          navigate('/guild/wallet-data/member-list')
          break
        case 3:
          navigate('/guild/historical')
          break
        default:
      }
      return
    }
    if (tab === 1) {
      switch (index) {
        case 1:
          navigate('/guild/task-data/member-detail/' + (refUser.current?.uid || ''))
          break
        case 2:
          navigate('/guild/wallet-data/member-detail/' + (refUser.current?.uid || ''))
          break
        case 3:
          navigate('/guild/historical/member/' + (refUser.current?.uid || ''))
          break
        default:
      }
      return
    }
  }, [navigate])

  /* agency function各图标点击 */
  const handleFuncClick = useCallback(
    (key: string) => {
      switch (key) {
        case 'member':
          setMemberList({
            ...refMemberList.current,
            visible: true
          })
          break
        case 'message':
          setFunctions(
            refFunctions.current.map((item) => {
              if (item.name === 'Message') {
                item.icon = ImageMessage
              }
              return item
            })
          )
          setApplyMessageList({
            ...refApplyMessageList.current,
            visible: true
          })
          // 通知客户端已读消息
          readedMessage(1)
          break
        case 'invite':
          setFansList({
            ...refFansList.current,
            visible: true
          })
          break
        case 'information':
          // setViewPayInfo(true)
          navigate('/wallet/collection-information')
          break
        case 'memberWD':
          setViewWithdraw(true)
          break
        // case 'exchange':
        //   setExchangeDraw(true)
        //   break
        case 'setting':
          navigate('/guild/setting')
          break
        // case 'setting2':
        //   navigate('/guild/agent-reward-data')
        //   break
        default:
      }
    },
    [navigate]
  )

  const handleAudit = useCallback(
    async (applyId: number, auditStatus: Guild.AuditStatus) => {
      setLoading(true)
      const res = await aduitGuildMemberApply(applyId, auditStatus)
      setLoading(false)
      if (res instanceof Error) {
        return
      }

      const { code, message } = res as Response<
        PageList<Guild.ApplyMessageItem>
      >
      if (code !== 200) {
        toast(message)
        return
      }

      const index = refApplyMessageList.current.list.findIndex(
        (item) => item.applyId === applyId
      )
      refApplyMessageList.current.list.splice(index, 1)
      setApplyMessageList({ ...refApplyMessageList.current })
      setMemberList({
        ...refMemberList.current,
        page: 1
      })
      fetchGuildMemberList()
    },
    [fetchGuildMemberList]
  )
  /* agency function => Invite：邀请按钮点击 */
  const handleInvite = useCallback(
    async (memberId: number) => {
      const res = await inviteMember(memberId)
      if (res instanceof Error) {
        return
      }

      const { code, message } = res as Response
      if (code !== 200) {
        toast(message)
        return
      }

      toast(t('Guild.Invite.InviteSuccess'))
    },
    [t]
  )

  const handleExitGuild = useCallback(async () => {
    const fn = refGuild.current?.memberRole === 1 ? dissolveGuild : exitGuild
    const res = await fn()
    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<Guild.ExitGuildReponse>
    if (code !== 200) {
      toast(message)
      return
    }
    if (refGuild.current?.memberRole !== 1) {
      toast(data!.successMsg)
      return
    }
    toast(t('Exit guild Success.'))
  }, [t])

  const handleMemberQuit = useCallback(async () => {
    const res = await getQuitCost()
    if (!res) return
    const { code, message, data } = res as Response<Guild.GuildCostReponse>
    if (code !== 200) {
      toast(message)
      return
    }
    return showAlertWithWarn(
      t('Guild.AreYouSureQuitAgency'),
      t('Guild.QuitTheQgencyMsg', { warn: data?.quitCost.toString() }),
      data?.quitCost.toString()
    )
  }, [])

  // 头部导航栏更多点击
  const handleMoreItemClick = useCallback(
    async (name: string) => {
      switch (name) {
        case 'Description':
          navigate('/guild/rule')
          break
        case 'Dissolve':
          let ret
          if (OwnerIdentity.includes(refRole.current)) ret = await alert(t('Guild.AreDisband'))
          else ret = await handleMemberQuit()
          if (ret) handleExitGuild()
          break
        default:
      }
    },
    [handleExitGuild]
  )
  /* my info点击跳转 */
  const handleMyInfo = useCallback(() => {
    navigate('/wallet/collection-information')
  }, [])

  const goToAgencyReward = useCallback(() => {
    navigate('/guild/agency-reward')
  }, [])

  const handleTabsClick = useCallback((tab: number) => {
    setSelectedTab(tab)
  }, [])

  // const handleInviteBtn = useCallback(() => {
  //   navigate('/guild/invite/invite-host')
  // }, [])

  const tabsControl = useMemo(() => {
    return {
      tabsTitle: ManagerIdentity.includes(role) ? ['Guild.AgencyData', 'Guild.MyData'] : ['Guild.MyData'],
      agencyShow: selectedTab === 0 && ManagerIdentity.includes(role),
      myShow: selectedTab === 1 || !ManagerIdentity.includes(role)
    }
  }, [role, selectedTab])

  const agencyDataFormat = useMemo(() => {
    let currentItemIndex = 0
    const currentItem = guildTotal?.rewardLevelConfList?.filter((item, index) => {
      if (item?.levelNumber === guildTotal?.rewardLevelNumber) {
        currentItemIndex = index
        return true
      }
    }).pop()
    if (guildTotal?.rewardLevelConfList) {
      currentItemIndex = currentItemIndex + 1 > (guildTotal?.rewardLevelConfList.length - 1) ? guildTotal?.rewardLevelConfList.length - 1 : currentItemIndex + 1
    }
    return {
      currentLevel: currentItem?.levelName,
      currentLevelRate: `${((currentItem?.rewardRate || 0) * 100)?.toFixed(0)}%`,
      nextLevel: guildTotal?.rewardLevelConfList[currentItemIndex].levelName,
      nextLevelRate: `${((guildTotal?.rewardLevelConfList[currentItemIndex].rewardRate || 0) * 100)?.toFixed(0)}%`,
      diffValue: (guildTotal?.rewardLevelConfList[currentItemIndex].levelMinValue || 0) - (guildTotal?.agencyAndSubAgencyGemIncome || 0),
      progress: Math.floor(((guildTotal?.agencyAndSubAgencyGemIncome || 0) / (guildTotal?.rewardLevelConfList[currentItemIndex].levelMinValue || 0)) * 100)
    }
  }, [guildTotal])

  return (
    <HelmetProvider>
      <Meta title={t('Guild.Tips.GuildPage').toString()} />
      <div className="pg pg-guild-home">
        <GuildHeader
          title={t('Guild.Tips.GuildPage').toString()}
          guild={guild && {
            guildName: guild.guildName,
            guildSlogan: guild.guildSlogan,
            coverPicUrl: guild.coverPicUrl,
            guildNo: guild.guildNo.toString(),
            guildLevelIcon: guild.guildLevelIcon
          }}
          infoCardItems={infoCardItems}
          onCardClick={handleCardClick}
          showMore={true}
          moreItems={[
            { name: 'Common.Description', val: 'Description' },
            {
              name: OwnerIdentity.includes(role) ? 'Common.Dissolve' : 'Common.Quit',
              val: 'Dissolve'
            }
          ]}
          totalMember={guild?.memberCount || 0}
          members={memberList.list}
          onMoreItemClick={handleMoreItemClick}
          showMsgIcon={ManagerIdentity.includes(guild?.memberRole || 3)}
          onShowMessage={() => handleFuncClick('message')}
          showSettingIcon={ManagerIdentity.includes(guild?.memberRole || 3)}
          onShowSetting={() => handleFuncClick('setting')}
          onInvite={() => handleFuncClick('invite')}
          onViewMember={() => handleFuncClick('member')}>
          <VipProgress
            progress={guild?.guildLevelPercent || 0}
            level={guild?.guildLevel || 1}
          />
        </GuildHeader>
        <div className='pg-guild-home-content'>
          <ScrollTabs tabsTitle={tabsControl.tabsTitle} selectedTab={selectedTab} handleTabsClick={handleTabsClick}></ScrollTabs>
          {/* Agency Data */}
          <div className={clsx({ hide: !tabsControl.agencyShow })}>
            <div className="pg-guild-home-agency-data">
              <div className='pg-guild-home-agency-data__href' onClick={goToAgencyReward}>
                <div className='pg-guild-home-agency-data__rate'>
                  <div className="left">
                    <span>{t('Guild.SemiMonthlyCommissionRate')}</span>
                    <div>{agencyDataFormat.currentLevel}:{agencyDataFormat.currentLevelRate}</div>
                  </div>
                  <img className='right' src={ImageArrow} alt="" />
                </div>
                <div className='pg-guild-home-agency-data__progress'>
                  <div className="top">
                    <span>{agencyDataFormat.currentLevel}:{agencyDataFormat.currentLevelRate}</span>
                    <span>{agencyDataFormat.nextLevel}:{agencyDataFormat.nextLevelRate}</span>
                  </div>
                  <ProgressBar percent={agencyDataFormat.progress} />
                  <div className='tip' dangerouslySetInnerHTML={{
                    __html: t('Guild.GemsAwayFromSLevel', {
                      gem: agencyDataFormat.diffValue?.toFixed(2),
                      level: agencyDataFormat.nextLevel
                    }).toString()
                  }}
                  />
                </div>
              </div>
              <div className='pg-guild-home-agency-data__info'>
                <div className='pg-guild-home-agency-data__light'>
                  <div className='pg-guild-home-agency-data__light-item'>
                    <span>{t('Guild.MyEstimatedTotalCommission')}</span>
                    <div className='gem'>
                      <img src={ImageGem} alt="" />
                      <span>{guildTotal?.rewardGem?.toFixed(2) || 0}</span>
                    </div>
                  </div>
                </div>
                <div className='pg-guild-home-agency-data__dark'>
                  <div className='pg-guild-home-agency-data__dark-item'>
                    <span>{t('Guild.MyAgencyGemIncome')}</span>
                    <div className='gem'>
                      <img src={ImageGem} alt="" />
                      <span>{guildTotal?.agencyGemIncome?.toFixed(2) || 0}</span>
                    </div>
                  </div>
                  <div className='pg-guild-home-agency-data__dark-item'>
                    <span>{t('Guild.HostWithGemIncome')}</span>
                    <div className='gem'>
                      <span>{guildTotal?.supportersNum || 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Wallet Data */}
            <GuildPane onClick={() => handleHistoricalCardClick(2, 0)} title={t('Guild.MonthlyMemberSalary')} titleExtra={<div className='arrowhead'></div>}>
              <HistoricalCard
                items={[
                  {
                    key: 'Common.Cash',
                    val: `$${guildTotal?.cashAmount || 0}`
                  },
                  { key: 'Common.Gem', val: guildTotal?.anchorDiamondNum || 0 }
                ]}
              />
              <HistoricalCard
                class={'wallet-data-card-last'}
                items={[
                  { key: 'Guild.ThisMonthlyCashIncome', val: guildTotal?.thisMonthCashIncome || 0 },
                  { key: 'Guild.ThisMonthlyGemIncome', val: guildTotal?.thisMonthGemIncome || 0 },
                ]}
              />
            </GuildPane>
            {/* Monthly Living Data */}
            <GuildPane onClick={() => handleHistoricalCardClick(3, 0)} title={t('Guild.LivingData')} titleExtra={<div className='arrowhead'></div>}>
              <HistoricalCard
                items={[
                  {
                    key: 'Common.Hours',
                    val: formatGuildTime(guildTotal?.micBroadcastDuration || 0)
                  },
                  { key: 'Common.ValidDay', val: `${guildTotal?.validDays || 0}` }
                ]}
              />
            </GuildPane>
            {/* Task Data */}
            {/* <GuildPane onClick={() => handleHistoricalCardClick(1, 0)} title={t('Guild.TaskData')} titleExtra={<div className='arrowhead'></div>}>
              <HistoricalCard
                items={[
                  {
                    key: 'Guild.TaskReward',
                    val: guildTotal?.taskReward || 0
                  },
                  { key: 'Guild.Completed', val: guildTotal?.taskCompleted || 0 }
                ]}
              />
            </GuildPane> */}
            {/* Agency Function */}
            {/* <GuildPane title={t('Guild.Tips.GuildFunction')}>
              <Func items={functions} onFuncClick={handleFuncClick} />
            </GuildPane> */}
          </div>
          {/* My Data */}
          <div className={clsx({ hide: !tabsControl.myShow })}>
            <div className='pg-guild-home__my-info' onClick={handleMyInfo}>
              <MemberCard
                memberId={Number(refUser.current?.uid) || 0}
                avatar={guild?.memberAvatar || ''}
                username={guild?.memberNick || ''}
                icons={guild?.medalIconList || []}
                showArrow={true}
              />
              <span>{t('Guild.JoinAgencyTime')} {moment(guild?.joinGuildTime || 1727511346).format('YYYY-MM-DD')}</span>
            </div>
            {/* Wallet Data */}
            <GuildPane onClick={() => handleHistoricalCardClick(2, 1)} title={t('Guild.MonthlySalary')} titleExtra={<div className='arrowhead'></div>}>
              <HistoricalCard
                items={[
                  {
                    key: 'Common.Cash',
                    val: `$${guildMyself?.cashAmount || 0}`
                  },
                  { key: 'Common.Gem', val: guildMyself?.anchorDiamondNum || 0 }
                ]}
              />
              <HistoricalCard
                class={'wallet-data-card-last'}
                items={[
                  { key: 'Guild.ThisMonthlyCashIncome', val: guildMyself?.thisMonthCashIncome || 0 },
                  { key: 'Guild.ThisMonthlyGemIncome', val: guildMyself?.thisMonthGemIncome || 0 },
                ]}
              />
            </GuildPane>
            {/* Monthly Living Data */}
            <GuildPane onClick={() => handleHistoricalCardClick(3, 1)} title={t('Guild.MonthlyLiving')} titleExtra={<div className='arrowhead'></div>}>
              <HistoricalCard
                items={[
                  {
                    key: 'Common.Hours',
                    val: formatGuildTime(guildMyself?.micBroadcastDuration || 0)
                  },
                  { key: 'Common.ValidDay', val: `${guildMyself?.validDays || 0}` }
                ]}
              />
            </GuildPane>
            {/* Task Data */}
            <GuildPane onClick={() => handleHistoricalCardClick(1, 1)} title={t('Guild.MonthlyTask')} titleExtra={<div className='arrowhead'></div>}>
              <HistoricalCard
                items={[
                  {
                    key: 'Guild.TaskReward',
                    val: guildMyself?.taskReward || 0
                  },
                  { key: 'Guild.Completed', val: guildMyself?.taskCompleted || 0 }
                ]}
              />
            </GuildPane>
            {/* Agency Function */}
            {/* <GuildPane title={t('Guild.Tips.GuildFunction')}>
              <Func items={functions} onFuncClick={handleFuncClick} />
            </GuildPane> */}
            {/* Tasks Center */}
            {userMissionData.missionList[0]?.missionTypeName &&
              <GuildPane title={t('Guild.TaskCenter')}>
                <TaskProgress missionList={userMissionData.missionList}></TaskProgress>
              </GuildPane>
            }
          </div>
        </div>
        {/* invite的按钮 */}
        {/* {unclaimedRewards.isBDUser &&
          <div className='pg-guild-home__invite-btn' onClick={handleInviteBtn}>
            <span>{t('Common.Invite')}</span>
            <img src={ImageArrow} alt="" />
          </div>
        } */}
        <SafeArea position="bottom" />
        {loading && <GuildLoading />}
      </div>

      {/* Popup弹出 */}
      <Popup
        className="pg-guild-home__member-list"
        visible={memberList.visible}
        onMaskClick={() => setMemberList({ ...memberList, visible: false })}
        bodyStyle={{
          backgroundColor: '#261A27'
        }}>
        <div className="pg-guild-home__member-list-header">
          <div className="pg-guild-home__member-list-title">
            {t('Common.MemberList')}
          </div>
          <div
            className="pg-guild-home__member-list-close"
            onClick={() => setMemberList({ ...memberList, visible: false })}>
            <img
              className="pg-guild-home__member-list-close-down"
              alt="member list close"
              src={ImageArrowDown}
            />
          </div>
        </div>
        <div className="pg-guild-home__member-list-content">
          <ScrollList
            hasMore={memberList.hasMore}
            loadMore={fetchGuildMemberList}
            empty={!memberList.list.length}
            emptyText={t('Guild.Tips.NoOverallDataYet')}>
            {memberList.list.map((item, i) => (
              <MemberCard
                myRole={role}
                memberId={item.uid}
                avatar={item.avatar}
                username={item.nick}
                icons={item.medalIconList}
                role={item.memberRole}
                onClick={handleMemberCardClick}
                manager={{
                  onManager: handleManagerClick
                }}
                key={i}
              />
            ))}
          </ScrollList>
        </div>
      </Popup>
      <Popup
        className="pg-guild-home__message-list pg-guild-home__message-list-apply"
        visible={applyMessageList.visible}
        onMaskClick={() =>
          setApplyMessageList({ ...applyMessageList, visible: false })
        }
        bodyStyle={{
          backgroundColor: '#261A27'
        }}>
        <div className="pg-guild-home__message-header">
          <div className="pg-guild-home__message-title">
            {t('Common.MessageList')}
          </div>
          <div
            className="pg-guild-home__message-close"
            onClick={() =>
              setApplyMessageList({ ...applyMessageList, visible: false })
            }>
            <img alt="member list close" src={ImageArrow} />
          </div>
        </div>
        <div className="pg-guild-home__message-content apply-content">
          <ScrollList
            hasMore={applyMessageList.hasMore}
            loadMore={fetchGuildApplyMessageList}
            empty={!applyMessageList.list.length}
            emptyText={t('Guild.Tips.NoOverallDataYet')}>
            {applyMessageList.list.map((item, i) => (
              <MessageCard
                memberId={item.uid}
                applyId={item.applyId}
                avatar={item.avatar}
                username={item.nick}
                icons={item.medalIconList}
                time={item.applyTime}
                key={i}
                onAudit={handleAudit}
              />
            ))}
          </ScrollList>
        </div>
      </Popup>
      <Popup
        className="pg-guild-home__fans-list"
        visible={fansList.visible}
        onMaskClick={() => setFansList({ ...fansList, visible: false })}
        bodyStyle={{
          backgroundColor: '#261A27'
        }}>
        <div className="pg-guild-home__fans-header">
          <div className="pg-guild-home__fans-title">{t('Common.Fans')}</div>
          <div
            className="pg-guild-home__fans-close"
            onClick={() => setFansList({ ...fansList, visible: false })}>
            <img alt="member list close" src={ImageArrow} />
          </div>
        </div>
        <div className="pg-guild-home__fans-content">
          <ScrollList
            hasMore={fansList.hasMore}
            loadMore={() => fetchGuildFansList(true)}
            empty={!fansList.list.length}
            emptyText={t('Guild.Tips.NoOverallDataYet')}>
            {fansList.list.map((item, i) => (
              <MemberCard
                memberId={item.uid}
                avatar={item.avatar}
                username={item.nick}
                icons={[item.experLevelIcon, item.charmLevelIcon].filter(
                  Boolean
                )}
                key={i}
                invite={{
                  enable: item.allowGuildInvite,
                  onInvite: handleInvite
                }}
              />
            ))}
          </ScrollList>
        </div>
      </Popup>
      <Popup
        className="pg-guild-home__withdraw"
        visible={viewWithdraw}
        onMaskClick={() => setViewWithdraw(false)}
        bodyStyle={{
          backgroundColor: '#261A27'
        }}>
        <Withdraw onClose={() => setViewWithdraw(false)} />
      </Popup>
      {/* 奖品弹窗*/}
      <Mask visible={viewRewardsDialog} opacity={0.6}>
        <div className='pg-guild-home__rewards-dialog'>
          <img className='pg-guild-home__close' src={ImageClose} onClick={() => setRewardsViewDialog(false)} />
          <div className={clsx('pg-guild-home__rewards-dialog-content', { 'success': unclaimedRewards.isOpen })}>
            <div className="pg-guild-home__rewards-dialog-title">
              <span>{t(`Guild.${unclaimedRewards.isOpen ? 'SuccessfullyReceived' : 'ExclusiveGifts'}`)}</span>
            </div>
            <div className="pg-guild-home__rewards-dialog-gifts">
              {unclaimedRewards.rewardList?.map((item, i) =>
                <div key={i}>
                  <img src={item.rewardIcon} alt="" />
                  <span>{item.rewardName}</span>
                </div>
              )}
            </div>
            <div className="pg-guild-home__rewards-dialog-btn">
              {unclaimedRewards.isOpen ?
                <span onClick={() => setRewardsViewDialog(false)}>{t('Guild.StartPlaying')}</span>
                :
                <span onClick={fetchOpenUnclaimedReward}>{t('Guild.Receive')}</span>
              }
            </div>
          </div>
        </div>
      </Mask>
      {/* <Popup
        className="pg-guild-home__pay-info fix-popup-safe-area"
        visible={viewPayInfo}
        onMaskClick={() => setViewPayInfo(false)}
        bodyStyle={{
          backgroundColor: '#261A27',
          height: `${577 / window.viewRate}px`
        }}>
        <PayInfo onClose={() => setViewPayInfo(false)} />
      </Popup> */}
      {/* <Popup
        className="pg-guild-home__withdraw"
        visible={exchangeDraw}
        onMaskClick={() => setExchangeDraw(false)}
        bodyStyle={{
          backgroundColor: '#261A27',
          height: `${333 / window.viewRate}px`
        }}>
        <Exchange
          onClose={() => setExchangeDraw(false)}
          exchangeDraw={exchangeDraw}
        />
      </Popup> */}
    </HelmetProvider>
  )
}
