import { service } from './net'
import { getUserInfo } from '@/utils/bridge'

// 搜索用户
export function getUser(params: any) {
  return service<Response<AgentRecharge.UserInfo>>({
    url: '/agency/search/user',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

//获取用户信息（没有传参数就拿当前用户信息）
export async function getMyUserInfo(
  uid?: number | string
): Promise<Response<AgentRecharge.UserInfo[]>> {
  const auth = await getUserInfo()
  return service<Response<Response<AgentRecharge.UserInfo[]>>>({
    url: 'user/activity/userInfo',
    params: { uids: uid ? uid : auth?.uid }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取公会详情
export function getGuildDetail(guildId: string) {
  return service<Response<Guild.Item>>({
    url: '/guild/getGuildInfo',
    params: { guildId }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 公会成员列表
export function getGuildMemberList(page: number, size = 20) {
  return service<Response<PageList<Guild.MemberItem>>>({
    url: '/guild/member/list',
    params: { pageNum: page, pageSize: size }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 申请加入公会
export function applyGuild(guildId: string) {
  return service<Response<unknown>>({
    method: 'POST',
    url: '/guild/join/apply',
    params: { guildId }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取公会申请消息列表
export function getGuildApplyMessageList(page: number, size = 20) {
  return service<Response<PageList<Guild.ApplyMessageItem>>>({
    url: '/guild/member/apply/list',
    params: { pageNum: page, pageSize: size }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取公会粉丝列表
export function getGuildFansList(params: object) {
  return service<Response<PageList<Guild.Fans>>>({
    url: '/guild/fans/list/v2',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 邀请加入公会
export function inviteMember(memberId: number) {
  return service<Response>({
    url: '/guild/join/invite',
    method: 'POST',
    params: { inviteUid: memberId }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 审核公会成员
export function aduitGuildMemberApply(
  applyId: number,
  auditStatus: Guild.AuditStatus
) {
  return service<Response<unknown>>({
    method: 'POST',
    url: '/guild/member/apply/audit',
    params: { applyId, auditStatus }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 公会主页详情新接口(公会详情)
export function getGuildHomeDetail(): Promise<Response<Guild.GuildHomeDetail>> {
  return service<Response<Guild.GuildHomeDetail>>({
    url: '/guild/home/detail'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 公会主页自己数据
export function getGuildHomeMyselfData(): Promise<
  Response<Guild.GuildHomeMyselfData>
> {
  return service<Response<Guild.GuildHomeMyselfData>>({
    url: '/guild/home/myself/data'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 公会主页公会数据
export function getGuildHomeGuildData(): Promise<
  Response<Guild.GuildHomeGuildData>
> {
  return service<Response<Guild.GuildHomeGuildData>>({
    url: '/guild/home/guild/data'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 更新公会信息
export function updateGuildInfo(params: Guild.UpdateInfo) {
  return service<Response>({
    method: 'POST',
    url: '/guild/update',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 退出公会
export function exitGuild() {
  return service<Response<Guild.ExitGuildReponse>>({
    method: 'POST',
    url: '/guild/member/quit'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 公会解散
export function dissolveGuild() {
  return service<Response>({
    method: 'POST',
    url: '/guild/dissolve'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取公会成员数据列表
export function getGuildHistoricalData(
  startTime: number,
  endTime: number,
  page: number,
  erbanNo?: number,
  size = 20
) {
  return service<Response<PageList<Guild.HistoricalDataItem>>>({
    url: '/guild/historicalDataForGuildId',
    params: {
      startTimeInt: startTime,
      endTimeInt: endTime,
      pageNum: page,
      pageSize: size,
      erbanNo
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取公会成员历史数据列表
export function getGuildMemberHistoricalData(
  memberId: string,
  startTime: number,
  endTime: number,
  page: number,
  size = 20
) {
  return service<
    Response<{
      memberSummaryData: Guild.HistoricalDataItem
      pageResult: PageList<Guild.MemberHistoricalDataItem>
    }>
  >({
    url: '/guild/historicalDataForUid',
    params: {
      memberUid: memberId,
      startTimeInt: startTime,
      endTimeInt: endTime,
      pageNum: page,
      pageSize: size
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取公会成员详情
export function getGuildMemberDetail(memberId: number) {
  return service<Response<Guild.GuileMemberItem>>({
    url: '/guild/member/card',
    params: {
      memberUid: memberId
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 踢出公会
export function kickOutGuild(memberId: number) {
  return service<Response<unknown>>({
    method: 'POST',
    url: '/guild/member/kick/out',
    params: {
      memberUid: memberId
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取用户账户信息
export function getWithdrawAccount() {
  return service<Response<Guild.PayInfo>>({
    url: '/guild/withdrawal/account'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 保存用户账户信息
export function saveWithdrawAccount(
  params: Omit<Guild.PayInfo, 'channelNameList'>
) {
  return service<Response>({
    url: '/guild/save/withdrawal/account',
    method: 'post',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取提现列表
export function getWithdrawList(
  params: Guild.WithdrawParam
): Promise<Response<Guild.WithdrawResponse>> {
  return service<Response>({
    url: '/guild/member/withdrawal/record',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 公会兑换状态
export function getExchangeStatus(): Promise<Guild.GuildStatusReponse> {
  return service<Response<Guild.GuildStatusReponse>>({
    url: 'guild/diamond/exchange/status'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 公会成员退出费用
export function getQuitCost(): Promise<Response<Guild.GuildCostReponse>> {
  return service<Response<Guild.GuildCostReponse>>({
    url: 'guild/member/quit/cost'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 通过邀请码获取公会信息
export function getGuildInfoFromInviteCode(inviteCode: string) {
  return service<Response<Guild.GuildInfoFromInviteCode>>({
    method: 'POST',
    url: 'guild/get/guild/info/inviteCode',
    params: {
      inviteCode
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 根据公会长UID获取公会信息
export function getGuildInfoFromId(founderUid: string) {
  return service<Response<Guild.GuildInfoFromId>>({
    method: 'GET',
    url: 'guild/getGuildInfoV2',
    params: {
      founderUid: founderUid ? founderUid : undefined
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 公会申请
export function guildCreateApply(params: {
  guildName: string
  coverPicUrl: string
  guildSlogan?: string
  inviteUid?: string
}) {
  return service<Response>({
    method: 'POST',
    url: 'guild/create/apply',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 申请公会配置信息
export function getGuildCreateConf() {
  return service<Response<Guild.GuildCreateConf>>({
    method: 'GET',
    url: 'guild/create/conf'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 申请创建的公会的信息
export function getGuildApplyInfo() {
  return service<Response<Guild.GuildApplyInfo>>({
    method: 'GET',
    url: 'guild/apply/info'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

/* 任务中心： */
// 个人页
export function getMissionUserHome() {
  return service<Response<Guild.GuildMissionUserHome>>({
    method: 'GET',
    url: 'mission/getUserHome'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 公会长详情页
export function getMissionMemberList(params: {
  startDate: string
  endDate: string
}) {
  return service<Response<Guild.GuildMissionMemberList[]>>({
    method: 'GET',
    url: 'mission/getGuildMemberDetail',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 个人任务详情页
export function getMissionUserDetail(params: {
  startDate: string
  endDate: string
  memberUid: number
  missionStatus?: number
}) {
  return service<Response<Guild.GuildMissionUserDetail>>({
    method: 'GET',
    url: 'mission/getUserDetail',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

/* 公会分润acgency reward */
// 公会奖励月数据
export function getRewardMonthData(monthDate: string, monthType: number) {
  return service<Response<Guild.GuildRewardMonthData>>({
    method: 'GET',
    url: 'guild/reward/month/data',
    params: {
      monthDate,
      monthType
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 获取公会成员数据
export function getMemberGemIncomeList(
  monthDate: string,
  pageNum: number,
  pageSize = 20
) {
  return service<Response<PageList<Guild.GuildMemberGemIncomeList>>>({
    method: 'GET',
    url: 'guild/get/guild/member/data',
    params: {
      monthDate,
      pageNum,
      pageSize
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 获取子公会数据
export function getSubGuildData(
  monthDate: string,
  pageNum: number,
  pageSize = 20
) {
  return service<Response<PageList<Guild.GuildSubGuildData>>>({
    method: 'GET',
    url: 'guild/sub/guild/data',
    params: {
      monthDate,
      pageNum,
      pageSize
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 获取子公会汇总数据
export function getSubGuildSubSummaryData(monthDate: string) {
  return service<Response<Guild.GuildSubSummaryData>>({
    method: 'GET',
    url: 'guild/sub/guild/summary/data',
    params: {
      monthDate
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 获取子公会信息
export function getSubGuildInfoList(subGuildType: number) {
  return service<Response<Guild.GuildSubGuildInfo[]>>({
    method: 'GET',
    url: 'guild/sub/guild/info',
    params: {
      subGuildType //子公会类型:1有效公会 2审核中公会 3已失效公会
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

/* wallet-data查看钱包 */
// 成员清单
export function getGuildMemberMonthBill(pageNum: number, pageSize = 20) {
  return service<Response<PageList<Guild.GuildMemberMonthBill>>>({
    method: 'GET',
    url: 'guild/member/month/bill',
    params: {
      pageNum,
      pageSize
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 成员清单详情
export function getGuildMemberMonthBillDetail(memberUid: string) {
  return service<Response<Guild.GuildMemberMonthBill>>({
    method: 'GET',
    url: 'guild/member/month/bill/forUid',
    params: {
      memberUid
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 通过类型获取主播账单记录
export function getMemberBillRecord(
  params: Guild.GuildMemberBillRecordParams,
  signal?: any
) {
  return service<Response<PageList<Guild.GuildMemberBillRecordListItem>>>({
    method: 'GET',
    url: 'billRecord/getAnchorBill',
    signal, //AbortController取消请求
    params: {
      memberUid: params.memberUid,
      billType: params.billType,
      lastTime: params.lastTime,
      beginDate: params.beginDate,
      endDate: params.endDate,
      pageSize: 20
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 用户每天收入汇总
export function getMemberDayIncome(params: {
  memberUid: string
  startTimeInt: string
  endTimeInt: string
}): Promise<Response<Guild.GuildMemberDayIncome[]>> {
  return service<Response<Guild.GuildMemberDayIncome[]>>({
    method: 'GET',
    url: 'guild/member/day/income',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

/* 分销奖励 */
// 分销奖励汇总
export function getAgencyChargeReward() {
  return service<Response<Guild.GuildAgencyChargeReward>>({
    method: 'GET',
    url: 'agency/charge/invite/reward/summary'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 分销奖励记录
export function getAgencyChargeRewardList(params: {
  startTime: string
  endTime: string
  pageNum: number
  pageSize?: number
}) {
  return service<Response<PageList<Guild.GuildAgencyChargeRewardList>>>({
    method: 'GET',
    url: 'agency/charge/invite/reward/record',
    params: {
      startTime: params.startTime,
      endTime: params.endTime,
      pageNum: params.pageNum,
      pageSize: 20
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//获取未领取的宝箱奖励
export function getUnclaimedRewards(): Promise<
  Response<Guild.UnclaimedRewards>
> {
  return service<Response<Guild.UnclaimedRewards>>({
    method: 'GET',
    url: 'reward/getUserReward'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//打开宝箱
export function openUnclaimedReward(): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: 'reward/openUserReward'
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//添加管理员
export function guildManagerAdd(managerUid: number): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: 'guild/manager/add',
    params: {
      managerUid
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//删除管理员
export function guildManagerDel(managerUid: number): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: 'guild/manager/del',
    params: {
      managerUid
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 公会搜索
export function guildSearch(params: { searchKey?: string, pageNum: number, pageSize?: number }): Promise<Response<PageList<Guild.GuildSearchListItem>>> {
  return service<Response<PageList<Guild.GuildSearchListItem>>>({
    method: 'GET',
    url: 'guild/search',
    params: {
      ...params,
      pageSize: 20
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// Cash提现撤回
export function cashWithdrawRevocation(recordId: number): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: 'withdrawal/cancelCash',
    params: {
      recordId
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}